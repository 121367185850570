import { fromJSON, mapJSONValues } from '../util/json'

export default class SignatureRound {
  static get ATTRIBUTES() {
    return {
      createdAt: (v) => fromJSON.date(v),
    }
  }

  static mapJSON(json) {
    return mapJSONValues(json, SignatureRound.ATTRIBUTES)
  }

  static fromJSON(json) {
    return Object.assign(new SignatureRound(), SignatureRound.mapJSON(json))
  }

  get url() {
    const { id, handbook } = this
    return `/d/${handbook.id}-${handbook.slug}/signature-rounds/${id}`
  }
}
